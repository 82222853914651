@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&family=IBM+Plex+Serif:wght@500,400&display=swap');
:root {
  --black: #212121;
  --grey: #474747;
  --wrapper: min(90vw, 1200px);
  --noOfColumns: 13;
  --ratioA: 1;
  --ratioB: 1;
  --gutter: 0px;
  --rowHeight: calc((var(--wrapper) / var(--noOfColumns)));
}

body {
  font-family: 'IBM Plex Sans', sans-serif;
  background-color: #ededed;
  color: var(--black);
}
@media only screen and (max-width: 768px) {
  .underline {
    width: 190px;
    height: 5px;
  }
}
